.lineChartsContainer {
  margin: 20px 0;
  /* display: flex;
  justify-content: space-between; */
}

.lineChart {
  margin-bottom: 10px;
  position: relative;
}

.lineChart button.copyToClipboard {
  /* display: none; hidden for now because copy function not implemented */
  position: absolute;
  z-index: 10;
  bottom: 10px;
  right: 10px;
}
