.OrbitalDataTable header {
  display: flex;
  justify-content: space-between;
}

.OrbitalDataTable header h5 {
  font-weight: 400;
  margin-bottom: 10px;
}

.OrbitalDataTable button.copyToClipboard {
  visibility: hidden;
  cursor: pointer;
  height: 26px;
}

.OrbitalDataTable:hover > header button.copyToClipboard {
  visibility: visible;
}

.OrbitalDataTable hr {
  width: 100px;
  border-width: 1px;
  margin: -5px 0 5px;
}

.OrbitalDataTable table {
  margin-bottom: 20px;
}

.OrbitalDataTable table.isLoading {
  opacity: 30%;
}

.OrbitalDataTable tr {
  line-height: 4px;
}

.OrbitalDataTable tr.totalRow {
  font-weight: 800;
  background-color: var(--elevatedBackground);
}

.OrbitalDataTable th {
  box-shadow: 1px 1px 0 0 rgba(255, 255, 255, 0.15) !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.OrbitalDataTable td {
  box-shadow: 1px 1px 0 0 rgba(255, 255, 255, 0.15) !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.OrbitalDataTable th.rowHeader {
  /* font-weight: 800; */
  background-color: var(--elevatedBackground);
}

.OrbitalDataTable td.columnHeader {
  text-align: left !important;
  /* font-weight: 800; */
}

.OrbitalDataTable td.negative {
  color: var(--intent-danger) !important;
}
